<template>
  <header class="b-header">
    <div class="b-header__logo">
      <router-link class="b-aclogo" to="/">
        <div class="b-aclogo__icon">
          <svg class="icon-svg">
            <use xlink:href="#svg-logo_header">

              <svg viewBox="0 0 8814334 6791129" id="svg-logo_header">
                <path class="flpl" d="M2944984 0l1846694 1066219c-74809,-4550 -151344,-6841 -229574,-6841 -377591,0 -711063,68502 -1000353,205569 -289289,137035 -519208,373010 -689725,707988l974245 649748c114661,-196563 454033,-439630 679307,-439630 207075,0 398711,113815 471794,211248 73052,97434 109641,194900 109641,292334l0 264938 -730865 0c-499753,0 -929654,55354 -1211789,346055 -125989,129787 -223831,275577 -287783,443082 -63952,167473 -95928,345616 -95928,534427 0,456794 109672,803948 328891,1041461 219250,237512 556550,402475 1001169,402475 255085,0 732088,-236289 888139,-443646l108166 848 -55605 298452c-702905,405425 -1406312,810914 -2108590,1216402 -980207,-565964 -1962579,-1131897 -2942818,-1697798 63,-1131865 0,-2263668 0,-3395533 980239,-565901 1964808,-1131834 2944984,-1697798zm2162029 3726463l-557304 0c-170485,0 -292709,36150 -365416,108103 -73460,72676 -109609,169011 -109609,289290 0,83752 35051,167504 105059,248182 70039,80677 196405,121816 379129,121816 146167,0 274070,-42645 383680,-126366 109609,-83753 164461,-207075 164461,-369998l0 -271027z"></path>
                <polygon class="frpl" points="6157510,1864926 6157510,4964580 8814334,3525445 "></polygon>
              </svg>
            </use>
          </svg>
        </div>
        <div class="b-aclogo__text">
          <div class="b-aclogo__title">
                <span style="color: #333">Тех</span>
            <span style="color: #bbc0c7">Автотест</span>
          </div>
          <div class="b-aclogo__descr">техосмотр без проблем</div>
        </div>
      </router-link>
    </div>
    <div class="b-header__recall">

    </div>
    <div class="b-header__contacts">
      <router-link to="/about"> </router-link>
      <div class="b-accontacts">
        <div class="b-accontacts__phone">
          <a href="tel:84953671173">+7 (495) 367 11 73</a>
        </div>
        <div class="b-accontacts__adress">
          г. Балашиха, Западная промзона, шоссе Энтузиастов, дом 2<br>
          Координаты пункта технического осмотра:
          Широта: 55.793365  Долгота: 37.89886
        </div>

        <div class="b-accontacts__mail">
          Эл.почта:
          <a href="mailto:info@tehavtotest.ru">info@tehavtotest.ru</a>
        </div>
      </div>
      <br />
    </div>
  </header>
</template>

<script>
export default {
  name: "MyHeader"
}
</script>

<style scoped>
.b-header{
  display: table;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
</style>