<template>
  <footer>
    <div class="container">
      <el-row>
        <el-col :span="24">
          <div class="sm-top_footer">
            <el-row>
              <el-col :span="12">
                <div class="block_logo">
                  <div class="footer_logo_wrap logo">
                    <router-link to="/"></router-link>
                  </div>
                  <div class=""></div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="box contacts">
                  <el-row>
                    <el-col :span="4">
                      <svg class="icon-svg svg-location">
                        <use xlink:href="#svg-location"></use>
                      </svg>
                    </el-col>
                    <el-col :span="20"
                            class=" in roboto "
                    >
                      <div class="phone">
                        <!--  <a href="tel:89166714311">+7 (916) 671-43-11</a> -->
                      </div>
                      <div class="phone">
                        <a href="tel:84953671173">+7 (495) 367-11-73</a>
                      </div>
                      <div>
                        г. Балашиха, Западная промзона, шоссе Энтузиастов, дом
                        2
                        <br>
                        Координаты пункта технического осмотра:
                        Широта: 55.793365 Долгота: 37.89886
                      </div>
                      <div>
                        E-mail:
                        <a href="mailto:info@tehavtotest.ru"
                        >info@tehavtotest.ru</a
                        >
                      </div>
                      <br/>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col col-sm-7 col-md-6 col-xs-6 foot-xxs-12"></div>
            <div class="col col-sm-5 col-md-6 col-xs-6 foot-xxs-12"></div>
          </div>
        </div>
      </el-row>
    </div>
    <div class="footer_copyright">
      <div class="container">
        <div class="row">
          <div class="col col-lg-7 col-md-8">
                <span class="all_rights"
                >© ООО «Техавтотест», 2021г.
                  ></span
                >
          </div>
          <div class="col col-lg-2 col-md-4 alright">
            <span id="bx-composite-banner"></span>
          </div>
          <div class="col col-lg-3 col-md-12 alright"></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter"
}
</script>

<style scoped>

</style>
