<template>
  <div class="features">
    <div class="container block-margin-bottom">
      <!--    <transition-group name="bar__tu__list" >
            <div v-for="item in items" :key="item.name" class="bar__tu__item">
              <a
                  class="feature clearfix"
                  href=""
                  target="_blank"
              >
                <svg class="feature__icon icon-svg">
                  <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      :xlink:href="'#'+item.svg"
                  ></use>
                </svg>
                <div class="feature__name">{{item.name}}</div>
              </a>
            </div>
          </transition-group> -->


    </div>
  </div>
</template>

<script>
import {ref} from 'vue'

export default {
  name: "BarTu",
  setup() {
    const items = ref([
      {svg: "svg-icon-flag", name: "Быстро и качественно"},
      {svg: "svg-icon-headset", name: "Постсервисные консультации"},
      {svg: "svg-icon-star", name: "Гарантия на все виды работ"},
      {svg: "svg-icon-gear", name: "Выполнение работ точно в строк"},
      {svg: "svg-icon-clock", name: "Нужные запчасти со своего склада"},
    ])
    return {
      items
    }
  }
}
</script>

<style scoped>
.bar__tu__item {
  width: 232px;
  float: left;
}
</style>