<template>
  <div class="gmap hidden-xs">
    <div class="block-margin">
      <div class="bx-yandex-view-layout">
        <div class="bx-yandex-view-map gmap-pointer-parent">
          <div
              id="BX_GMAP_main"
              class="bx-google-map"
              style="height: 360px; width: 100%"
          >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471.5343842461045!2d37.90077589884793!3d55.79309588417318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414acb7f000ba8bb%3A0x13d2e0bf4866c8af!2z0YguINCt0L3RgtGD0LfQuNCw0YHRgtC-0LIsIDLRgSwg0JHQsNC70LDRiNC40YXQsCwg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC7LiwgMTQzOTEy!5e0!3m2!1sru!2sru!4v1610986776573!5m2!1sru!2sru" width="100%" height="351" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

          </div>
          <div class="gmap-pointer hidden-xs">
            <div class="gmap-pointer__icon">
              <svg class="icon-svg svg-location">
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#svg-location"
                ></use>
              </svg>
            </div>

            <div class="gmap-pointer__body">
              <strong
              >г. Балашиха, Западная промзона, шоссе Энтузиастов, дом
                2</strong
              ><br />
              График работы:<br />
              с 9:00 до 18:00 пн.-пт.<br />
              Суббота, воскресенье — по предварительной записи<br />
              Телефон: 8 (495) 367 11 73 <br />
              <div>
                <a href="mailto:info@tehavtotest.ru"
                >info@tehavtotest.ru</a
                >
              </div>
              <b
              ><span style="color: black"
              >ВНИМАНИЕ! В навигаторе "Яндекс" нужно вводить:
                          Балашиха, шоссе Энтузиастов, с2а</span
              ></b
              ><br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import GMap from "@/components/Ymap";

export default {

  name: "FooterMap",
  components: {},
  setup() {


    return {}
  }
}
</script>

<style scoped>
.gmap-pointer {
  position: absolute;
  top: 50px;
  left: 60%;
  width: 100%;
  max-width: 350px;
  padding: 28px 25px;
  margin: auto;
  margin-left: 15px;
  background-color: #4ba82e;
}
.gmap-pointer:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  content: ' ';
}

</style>